
import { Component, Vue } from 'vue-property-decorator'
import LangSelect from '@/components/lang-select'
import { SiteStateModule, SiteType } from '@/store/site_state'
import SiteSelect from './com/site-select'

@Component({
  name: 'PageEntryLayoutToolbar',
  components: {
    LangSelect,
    SiteSelect
  }
})
export default class PageEntryLayoutToolbar extends Vue {
  get lang() {
    return SiteStateModule.lang
  }

  set lang(l: string) {
    SiteStateModule.updateLang(l)
  }

  get allowSwitchSite() {
    return SiteStateModule.allowSwitchSite
  }

  get site(): SiteType | null {
    return SiteStateModule.site
  }

  set site(s: SiteType | null) {
    SiteStateModule.updateSite(s)
  }
}
