
import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator'
import ICON_EN from './img/gb.png'
import ICON_VN from './img/vn.png'
import ICON_CN from './img/cn.png'

interface LangItem {
  name: string
  displayName: string
  icon: any
}

const defaultSupportLang: Array<LangItem> =
  process.env.VUE_APP_THEME !== 'tablink'
    ? [
        {
          name: 'en-GB',
          displayName: 'English',
          icon: ICON_EN
        },
        {
          name: 'vi-VN',
          displayName: 'Tiếng Việt',
          icon: ICON_VN
        }
        // {
        //   name: 'zh-CN',
        //   displayName: '简体中文',
        //   icon: ICON_CN
        // }
      ]
    : [
        {
          name: 'en-GB',
          displayName: 'English',
          icon: ICON_EN
        },

        {
          name: 'zh-CN',
          displayName: '简体中文',
          icon: ICON_CN
        }
      ]

const prefixCls = 'cpfui-lang-select'

const defaultLang = 'en-GB'

@Component({
  name: 'LangSelect'
})
export default class LangSelect extends Vue {
  readonly prefixCls = prefixCls

  @VModel({ type: String, default: defaultLang }) lang!: string

  @Prop() supportedLang?: Array<LangItem>
  @Prop({ default: false }) hideFlag?: boolean

  get languageText() {
    let selectedItem: LangItem | null = null

    this.supportLanguage.some((item) => {
      if (item.name === this.lang) {
        selectedItem = item
        return true
      }
    })

    if (selectedItem !== null) {
      return (
        (selectedItem as LangItem).displayName ||
        (selectedItem as LangItem).name
      )
    } else {
      return defaultLang
    }
  }

  get languageIcon() {
    let selectedItem: LangItem | null = null
    this.supportLanguage.some((item) => {
      if (item.name === this.lang) {
        selectedItem = item
        return true
      }
    })
    return selectedItem && (selectedItem as LangItem).icon
  }

  get supportLanguage(): Array<LangItem> {
    return this.supportedLang || defaultSupportLang
  }

  @Emit('on-select')
  clickLangItem(item: any) {
    this.lang = item.key
    // 切换语言
    return item.key
  }
}
